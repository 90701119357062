import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as BrowserLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import L from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import markerRetina from 'leaflet/dist/images/marker-icon-2x.png';
import marker from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import { Avatar, Box, Container, Link, Typography } from '@material-ui/core';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ContactForm } from '../components/contact';
import Logo from '../components/Logo';
import gtm from '../lib/gtm';
import { sobit } from '../config';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerRetina,
  iconUrl: marker,
  shadowUrl: markerShadow,
});

const Contact = () => {
  const i18n = useTranslation();
  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {i18n.t('imprint:label')}
          {' | '}
          SOBIT
        </title>
      </Helmet>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            lg: 'repeat(2, 1fr)',
            xs: 'repeat(1, 1fr)'
          },
          minHeight: '100%'
        }}
      >
        <Box
          sx={{
            backgroundColor: 'background.default',
            pt: 8
          }}
        >
          <Container
            maxWidth="md"
            sx={{
              pl: {
                lg: 15
              }
            }}
          >
            <Link
              component={BrowserLink}
              to="/"
            >
              <ArrowBackIcon />
            </Link>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                py: 3
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: 'primary.main',
                  color: 'primary.contrastText',
                  mr: 2
                }}
                variant="rounded"
              >
                <BusinessCenterIcon/>
              </Avatar>
              <Typography
                color="textPrimary"
                variant="overline"
              >
                {i18n.t('imprint:label')}
              </Typography>
            </Box>
            <Typography
              color="textPrimary"
              sx={{ fontWeight: 'fontWeightBold' }}
              variant="h1"
            >
              {i18n.t('imprint:title')}
            </Typography>
            <Typography
              color="textPrimary"
              sx={{ py: 3 }}
              variant="body1"
            >
              {i18n.t('imprint:subtitle')}
              :
            </Typography>
            {sobit.business.registered && (
              <Typography
                color="textPrimary"
                sx={{
                  pb: 1.5,
                  pt: 0.5
                }}
                variant="h5"
              >
                {sobit.business.name}
              </Typography>
            )}
            <Typography
              color="textPrimary"
              sx={{ py: 0.25 }}
              variant="body1"
            >
              {i18n.t('imprint:company.owner')}
              <br/>
              {i18n.t('imprint:company.address.street')}*
              <br/>
              {i18n.t('imprint:company.address.location')}
              <br/>
              {i18n.t('imprint:company.address.country')}
            </Typography>
            <Typography
              color="textPrimary"
              sx={{ py: 1 }}
              variant="body1"
            >
              <strong>{i18n.t('imprint:company.businessLine.label')}</strong>
              :
              {' '}
              {i18n.t('imprint:company.businessLine.value')}
              <br/>
              <strong>{i18n.t('imprint:company.uid-number.label')}</strong>
              :
              {' '}
              {sobit.business.uid}
              <br/>
              {sobit.business.registered && (
                <>
                  <strong>{i18n.t('imprint:company.company-reg-number.label')}</strong>
                  :
                  {' '}
                  {sobit.business.companyRegNumber}
                  <br/>
                  <strong>{i18n.t('imprint:company.company-reg-court.label')}</strong>
                  :
                  {' '}
                  <Link href={sobit.business.companyRegCourt.link}>
                    {sobit.business.companyRegCourt.name}
                  </Link>
                  <br/>
                </>
                )
              }
              <strong>{i18n.t('imprint:company.company-location.label')}</strong>
              :
              {' '}
              {i18n.t('imprint:company.company-location.value')}
              <br/>
              <strong>{i18n.t('imprint:company.regulatory-authority.label')}</strong>
              :
              {' '}
              {sobit.business.regulatoryAuthority.name}
            </Typography>
            <Typography
              color="textPrimary"
              sx={{ pt: 1 }}
              variant="body1"
            >
              <strong>
                {i18n.t('imprint:contact.email')}
                :
              </strong>
              {' '}
              <Link href={'mailto:' + sobit.business.email}>
                {sobit.business.email}
              </Link>
            </Typography>
            <Typography
              color="textPrimary"
              sx={{ pt: 0.25 }}
              variant="body1"
            >
              <strong>
                {i18n.t('imprint:contact.telephone')}
                :
              </strong>
              {' '}
              <Link href={'tel:' + sobit.business.telephoneNumber.machine}>
                {sobit.business.telephoneNumber.human}
              </Link>
            </Typography>
            <Box sx={{
              pt: 4,
              pb: 4
            }}>
              <MapContainer center={sobit.officePosition} zoom={15} style={{
                width: '100%',
                height: '25rem'
              }}>
                <TileLayer
                  attribution='Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
                  url="https://api.mapbox.com/styles/v1/kevinhaller/cksot7wjv016517pl51dmd028/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoia2V2aW5oYWxsZXIiLCJhIjoiY2tzbmo2bzRjMXBnajJ3b3g4enRueWtmayJ9.iiotmck1a0aLuiPhJTaCXw"
                />
                <Marker position={sobit.officePosition}>
                  <Popup>
                    {i18n.t('imprint:office.label')}
                  </Popup>
                </Marker>
              </MapContainer>
            </Box>
            <Box sx={{
              pb: 4
            }}>
              <Typography
                color="textPrimary"
                variant="body1"
              >
                * {i18n.t('imprint:po-box-hint')}
              </Typography>
            </Box>
          </Container>
        </Box>
        <Box
          sx={{
            backgroundColor: 'background.paper',
            pt: 8
          }}
        >
          <Container
            maxWidth="md"
            sx={{
              pr: {
                lg: 15
              }
            }}
          >
            <Typography
              color="textPrimary"
              variant="h6"
              sx={{ pb: 3 }}
            >
              {i18n.t('contact-form:title')}
            </Typography>
            <Box sx={{ pb: 4 }}>
              <ContactForm/>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Contact;
