import { Grid } from '@material-ui/core';
import AssignedBlocksItem from './items/performance/AssignedBlocksItem';
import ExpectedBlocksItem from './items/performance/ExpectedBlocksItem';
import MaxPerformanceItem from './items/performance/MaxPerformanceItem';
import MintedBlocksItem from './items/performance/MintedBlocksItem';
import DoubleAssignedBlocksItem from './items/performance/DoubleAssignedBlocks';
import LostInHeightBattleBlocksItem from './items/performance/LostInHeightBattleBlocks';
import GhostedBlocksItem from './items/performance/GhostedBlocks';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchPerformance } from '../../slices/performance';
import { useEffect } from 'react';

const mapToProps = (state) => ({ performance: state.performance });

function fetch(dispatch, epoch, status) {
  if (!status || !(epoch in status) || !(status[epoch].timestamp) || (status[epoch].type === 'FAILED'
    && (new Date().getTime() - new Date(status[epoch].timestamp)) > 60000)) {
    dispatch(fetchPerformance(epoch));
  }
}

const EpochPerformanceOverview = ({ dispatch, epoch, performance: { status, result } }) => {
  useEffect(() => {
    fetch(dispatch, epoch, status);
  }, [epoch, status]);

  const epochResult = result[epoch];
  return (
    <Grid
      container
      spacing={3}
    >
      <AssignedBlocksItem
        assignedBlocks={epochResult ? epochResult.assignedBlocks : 0}
        xs={12}
        sm={6}
        md={4}
        lg={4}
      />
      <ExpectedBlocksItem
        expectedBlocks={epochResult ? epochResult.expectedBlockNumber : 0}
        xs={12}
        sm={6}
        md={4}
        lg={4}
      />
      <MaxPerformanceItem
        maxPerformance={epochResult ? epochResult.maxPerformance : 0}
        xs={12}
        sm={6}
        md={4}
        lg={4}
      />
      <MintedBlocksItem
        mintedBlocks={epochResult && epochResult.status ? epochResult.status.minted : 0}
        xs={12}
        sm={6}
        md={3}
      />
      <DoubleAssignedBlocksItem
        doubleAssignedBlocks={epochResult && epochResult.status ? epochResult.status.doubleAssigned : 0}
        xs={12}
        sm={6}
        md={3}
      />
      <LostInHeightBattleBlocksItem
        heightBattleBlocks={epochResult && epochResult.status ? epochResult.status.heightBattle : 0}
        xs={12}
        sm={6}
        md={3}
      />
      <GhostedBlocksItem
        ghostedBlocks={0}
        xs={12}
        sm={6}
        md={3}
      />
    </Grid>
  );
};

EpochPerformanceOverview.propTypes = {
  dispatch: PropTypes.func,
  epoch: PropTypes.number,
  performance: PropTypes.any,
};

export default connect(mapToProps)(EpochPerformanceOverview);
