import { AppBar, Box, Divider, IconButton, Toolbar } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import WbSunnyOutlinedIcon from '@material-ui/icons/WbSunnyOutlined';
import Brightness2OutlinedIcon from '@material-ui/icons/Brightness2Outlined';
import LanguagePopover from './LanguagePopover';
import useSettings from '../hooks/useSettings';

const HomeNavbar = () => {
  const {
    settings,
    saveSettings
  } = useSettings();
  const [theme, setTheme] = useState(settings.theme);
  const [onTop, setOnTop] = useState(window.pageYOffset === 0);
  const handleScroll = useCallback(() => {
    if (window.pageYOffset > 200 && onTop) {
      setOnTop(false);
    }
    if (window.pageYOffset <= 200 && !onTop) {
      setOnTop(true);
    }
  }, [onTop]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  function handleThemeChange(mode) {
    setTheme(mode);
    saveSettings({
      ...settings,
      theme: mode
    });
  }

  return (
    <AppBar
      elevation={0}
      sx={{
        color: 'text.secondary',
        backgroundColor: onTop ? 'transparent' : 'background.paper'
      }}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <Box sx={{ flexGrow: 1 }} />
        <LanguagePopover color="inherit" />
        {theme === 'DARK' ? (
          <IconButton
            aria-label="light mode"
            color="inherit"
            onClick={() => handleThemeChange('LIGHT')}
          >
            <WbSunnyOutlinedIcon />
          </IconButton>
        ) : null}
        {theme === 'LIGHT' ? (
          <IconButton
            aria-label="dark mode"
            color="inherit"
            onClick={() => handleThemeChange('DARK')}
          >
            <Brightness2OutlinedIcon />
          </IconButton>
        ) : null}
      </Toolbar>
      {!onTop && <Divider />}
    </AppBar>
  );
};

export default HomeNavbar;
