import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

const sxItemStartTimeStyle = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  p: 3
};

const EpochTimeItem = ({
  time,
  lg,
  md,
  sm,
  xs,
}) => {
  const i18n = useTranslation();
  return (
    <Grid
      item
      lg={lg}
      md={md}
      sm={sm}
      xs={xs}
      sx={sxItemStartTimeStyle}
    >
      <div>
        <Typography
          color="textSecondary"
          variant="overline"
        >
          {i18n.t('dashboard:section.overview.label.start')}
        </Typography>
        <Typography
          color="textPrimary"
          variant="h3"
        >
          {time !== null ? format(time, 'do MMM, p') : '...'}
        </Typography>
      </div>
    </Grid>
  );
};

EpochTimeItem.propTypes = {
  time: PropTypes.instanceOf(Date),
  lg: PropTypes.any,
  md: PropTypes.any,
  sm: PropTypes.any,
  xs: PropTypes.any,
};

export default EpochTimeItem;
