import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const DashSection = ({ title, component }) => (
  <Grid
    key={`section-${title}`}
    container
    spacing={3}
    sx={{
      '& + &': {
        mt: 8,
        pb: 8,
        borderTop: 1,
        borderColor: 'divider',
        pt: 5
      }
    }}
  >
    <Grid
      item
      lg={3}
      xs={12}
      sx={{ mb: 3 }}
    >
      <Typography
        color="textPrimary"
        sx={{ fontWeight: 600 }}
        variant="h5"
      >
        {title}
      </Typography>
    </Grid>
    <Grid
      container
      item
      lg={9}
      spacing={0}
      xs={12}
    >
      {component}
    </Grid>
  </Grid>
);

DashSection.propTypes = {
  title: PropTypes.string.isRequired,
  component: PropTypes.any,
};

export default DashSection;
