import { Grid, Card } from '@material-ui/core';
import EpochItem from '../items/epoch/EpochItem';
import SlotItem from '../items/epoch/SlotItem';
import { alpha } from '@material-ui/core/styles';
import EpochEndTimeItem from '../items/epoch/EpochEndTimeItem';
import EpochStartTimeItem from '../items/epoch/EpochStartTimeItem';
import PropTypes from 'prop-types';

const sxItemStyle1 = {
  alignItems: 'center',
  borderRight: (theme) => ({
    md: `1px solid ${theme.palette.divider}`
  }),
  borderBottom: (theme) => ({
    md: 'none',
    xs: `1px solid ${theme.palette.divider}`
  }),
  display: 'flex',
  justifyContent: 'space-between',
  p: 3
};

const EpochOverview = ({
  epoch,
  slot,
  startTime,
  endTime,
  sx
}) => (
  <Card sx={sx}>
    <Grid
      container
      sx={{ background: (theme) => alpha(theme.palette.background.default, 0.5) }}
    >
      <EpochItem
        epoch={epoch}
        md={2}
        sm={6}
        xs={6}
        sx={sxItemStyle1}
      />
      <SlotItem
        slot={slot}
        md={2}
        sm={6}
        xs={6}
        sx={sxItemStyle1}
      />
      <EpochStartTimeItem
        time={startTime}
        md={4}
        sm={6}
        xs={6}
      />
      <EpochEndTimeItem
        time={endTime}
        md={4}
        sm={6}
        xs={6}
      />
    </Grid>
  </Card>
);

EpochOverview.propTypes = {
  epoch: PropTypes.number,
  slot: PropTypes.number,
  startTime: PropTypes.instanceOf(Date),
  endTime: PropTypes.instanceOf(Date),
  sx: PropTypes.any,
};

export default EpochOverview;
