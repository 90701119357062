import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const SlotItem = ({
  slot,
  lg,
  md,
  sm,
  xs,
  sx
}) => {
  const i18n = useTranslation();
  return (
    <Grid
      item
      lg={lg}
      md={md}
      sm={sm}
      xs={xs}
      sx={sx}
    >
      <div>
        <Typography
          color="textSecondary"
          variant="overline"
        >
          {i18n.t('dashboard:section.overview.label.slot')}
        </Typography>
        <Typography
          color="textPrimary"
          variant="h3"
        >
          {slot !== null ? slot : '...'}
        </Typography>
      </div>
    </Grid>
  );
};

SlotItem.propTypes = {
  slot: PropTypes.number,
  lg: PropTypes.any,
  md: PropTypes.any,
  sm: PropTypes.any,
  xs: PropTypes.any,
  sx: PropTypes.any,
};

export default SlotItem;
