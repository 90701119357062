const privacy = {
  title: 'Privacy Policy',
  subtitle: 'We are dedicated to protect your personal privacy.',
  content: `
  # Your rights
We would like to make sure you are fully aware of all of your data protection rights. Every
user is entitled to the following.

**The right to access** - You have the right to request us for copies of the personal data stored by us.

**The right to rectification** - You have the right to request that we correct any information you believe
is inaccurate. You also have the right to request to complete information you believe is incomplete.

**The right to erasure** - You have the right to request that we erase your personal data.

**The right to restrict processing** - You have the right to request that we restrict the processing of your
personal data.

**The right to data portability** - You have the right to request that we transfer the data that we have
collected to another organization, or directly to you, under certain conditions.

Please contact us per [email](mailto:support.staking@outofbits.com).

# What information do we collect?
We are collecting no personal information about you and we are not
using any tracking software.

# Do we use cookies?
We are not using cookies!

# Third party links
Our website contains links to other websites, and we are operating a Discord
server and Telegram channel for our community. This privacy policy applies only to this website, and we
have no power over how Discord, Telegram and other linked websites are handling your
personal data.

# Changes to our privacy policy
You can always find the current policy on this web page. It was last updated on 29th of August 2021.
`,
};

export default privacy;
