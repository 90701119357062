import { experimentalStyled } from '@material-ui/core/styles';

const LogoRoot = experimentalStyled('svg')``;

const Lockup = (props) => (
  <LogoRoot
    height={250}
    width={250}
    version="1.1"
    viewBox="0 0 66.145832 66.145836"
    {...props}
  >
    <title>Logo of this pool</title>
    <circle
      style={{
        fill: '#f50057',
        fillOpacity: 1,
        strokeWidth: 0.499999,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        stopColor: '#000000',
      }}
      id="path13362"
      cx="33.072872"
      cy="31.188774"
      r="28.849329"/>
    <g
      id="g7135"
      transform="translate(-6.0375503,-16.248708)">
      <path
        d="m 45.838174,44.376545 c -1.827792,-1.797411 -3.336308,-3.074471 -4.638168,-4.055063 -1.556636,-1.172495 -2.139838,-1.085729 -2.523277,-0.340083 -0.366116,0.711959 -0.347974,1.824443 0.70293,2.583884 1.102112,0.796439 3.136778,2.533681 5.812475,4.236874 z"
        fill="#b2a99c"
        id="path4"/>
      <path
        d="m 45.712466,44.726449 c -1.891672,-1.656539 -3.435464,-3.010557 -4.739477,-3.988283 -0.99839,-0.748575 -1.61068,-0.872529 -1.820888,-0.414504 -0.237317,0.51709 -0.08416,1.334095 0.60736,1.947404 1.017307,0.902247 2.908117,2.401841 5.583814,4.105035 z"
        fill="#3f3737"
        id="path6"/>
      <path
        d="m 46.633299,42.54723 c -1.656275,-1.956595 -3.042054,-3.365893 -4.249072,-4.461113 -1.443234,-1.309556 -2.031918,-1.276332 -2.481753,-0.568758 -0.429513,0.675608 -0.512891,1.785111 0.464383,2.637218 1.024895,0.893619 2.892668,2.80916 5.401907,4.749248 z"
        fill="#b2a99c"
        id="path8"/>
      <path
        d="m 46.476208,42.884213 c -1.732735,-1.822135 -3.146625,-3.311286 -4.356049,-4.403849 -0.925969,-0.836497 -1.524405,-1.015768 -1.775504,-0.57882 -0.28348,0.493296 -0.205466,1.320862 0.42725,1.994675 0.930795,0.991254 2.676983,2.657019 5.186222,4.597107 z"
        fill="#3f3737"
        id="path10"/>
      <path
        d="m 47.590166,41.120233 c -1.465891,-2.103016 -2.713693,-3.635822 -3.812914,-4.839198 -1.31434,-1.438878 -1.903549,-1.460894 -2.417631,-0.798525 -0.490857,0.632445 -0.677706,1.729275 0.215532,2.669104 0.936764,0.985615 2.617066,3.067551 4.933722,5.23396 z"
        fill="#b2a99c"
        id="path12"/>
      <path
        d="m 47.402227,41.441035 c -1.554599,-1.976302 -2.822916,-3.591241 -3.924781,-4.792197 -0.843618,-0.919485 -1.42265,-1.153976 -1.71354,-0.742446 -0.328402,0.4646 -0.328182,1.295835 0.238696,2.025906 0.83394,1.074015 2.416567,2.895892 4.733223,5.062301 z"
        fill="#3f3737"
        id="path14"/>
      <path
        d="m 48.666603,39.348873 c -1.305718,-2.20604 -2.385012,-3.908659 -3.391861,-5.19032 -1.203891,-1.532484 -1.763275,-1.383698 -2.299927,-0.882765 -0.585236,0.546284 -0.874189,1.774229 -0.05319,2.777778 0.861007,1.052441 2.349062,3.141703 4.498491,5.474122 z"
        fill="#b2a99c"
        id="path16"/>
      <path
        d="m 48.349673,39.835668 c -1.305718,-2.20604 -2.281271,-3.895974 -3.273246,-5.189181 -0.805427,-1.050009 -1.295915,-1.455928 -1.706395,-0.842868 -0.411092,0.613973 -0.537229,1.396163 0.283771,2.399712 0.861007,1.052441 1.988527,2.468521 4.041679,4.921031 z"
        fill="#3f3737"
        id="path18"/>
      <path
        d="m 49.587261,37.883805 c -0.988918,-2.365069 -1.732113,-3.876332 -2.552535,-5.284631 -0.98098,-1.683906 -1.571945,-1.937327 -2.203127,-1.309007 -0.567379,0.564807 -0.946651,1.74694 -0.27194,2.854149 0.707594,1.161153 1.685339,3.118898 3.492468,5.725525 z"
        fill="#b2a99c"
        id="path20"/>
      <path
        d="m 49.306577,38.190048 c -0.988918,-2.365069 -1.675199,-3.826476 -2.509718,-5.226468 -0.749881,-1.258004 -1.133062,-1.549948 -1.578221,-1.138014 -0.413886,0.382997 -0.699817,1.134076 -0.12397,2.273946 0.613134,1.213685 1.46001,2.797427 3.267139,5.404054 z"
        fill="#3f3737"
        id="path22"/>
      <path
        d="m 50.300055,35.71418 c -0.547846,-2.504271 -1.037131,-4.254445 -1.591144,-5.787243 -0.662434,-1.832769 -1.423005,-2.312978 -2.075292,-1.867425 -0.661079,0.451557 -1.160036,1.916916 -0.695257,3.127342 0.487431,1.2694 1.151507,3.370438 2.46084,6.25936 z"
        fill="#b2a99c"
        id="path24"/>
      <path
        d="m 50.065925,35.881534 c -0.470544,-2.150911 -1.261628,-4.13254 -1.737468,-5.449056 -0.568963,-1.574159 -0.935833,-2.108185 -1.49608,-1.7255 -0.567799,0.387841 -0.817759,1.534445 -0.418561,2.574077 0.418653,1.090284 1.125039,3.403955 2.249621,5.885241 z"
        fill="#3f3737"
        id="path26"/>
      <path
        d="m 51.598935,34.387953 c -0.06873,-2.562574 -0.221287,-4.37344 -0.47817,-5.982915 -0.307155,-1.924453 -0.80214,-2.460127 -1.565894,-2.195782 -0.756547,0.261851 -1.644307,1.642378 -1.41465,2.918469 0.240853,1.338266 0.499335,3.52654 1.243956,6.609682 z"
        fill="#b2a99c"
        id="path28"/>
      <path
        d="m 51.308235,34.63192 c -0.06873,-2.562574 -0.309326,-4.470591 -0.610349,-6.072398 -0.184254,-0.980455 -0.540509,-1.86539 -0.947021,-1.697547 -0.617722,0.255048 -1.376026,1.247565 -1.236826,2.330668 0.169506,1.318917 0.548664,3.227612 1.225264,6.34865 z"
        fill="#3f3737"
        id="path30"/>
      <path
        d="m 53.152297,34.055306 c 0.521775,-2.509833 0.789282,-4.307317 0.908992,-5.932761 0.143137,-1.943547 -0.07669,-2.774163 -0.844114,-2.544056 -0.76685,0.229935 -2.086568,1.318033 -2.156191,2.612755 -0.07301,1.357805 -0.324123,3.546937 -0.307663,6.71868 z"
        fill="#b2a99c"
        id="path32"/>
      <path
        d="m 52.758698,34.312572 c 0.521775,-2.509833 0.779854,-4.594827 0.883565,-6.124389 0.06444,-0.95043 0.05662,-2.070022 -0.53561,-1.925837 -0.777859,0.189377 -1.545291,1.146141 -1.644283,2.213417 -0.125582,1.353955 -0.311416,3.271144 -0.294955,6.442887 z"
        fill="#3f3737"
        id="path34"/>
      <path
        d="m 26.498639,62.019986 -2.29392,2.162659 c -1.226837,2.843551 -9.756914,15.872335 -5.940627,15.872335 1.04419,0 2.93444,-1.373227 3.6775,-2.518558 0.51054,0.659 0.9348,0.807201 1.339837,0.807201 1.71796,0 2.10989,-2.997541 3.7593,-11.786446 z"
        fill="#3f3737"
        id="path36"/>
      <path
        d="m 30.419346,37.740684 c 0,0 -1.937667,-2.22425 -0.40615,-2.22425 1.03612,0 1.79756,2.925866 3.973607,4.31181 z"
        fill="#524848"
        id="path38"/>
      <path
        d="m 16.792228,22.664434 c -0.658595,0.119986 -0.949134,2.623293 -0.358888,3.989926 1.004432,1.979026 2.612861,3.573011 3.715988,4.434783 1.699713,1.32783 3.064193,2.317113 5.599989,4.186749 l 0.553131,-1.015581 c -2.397374,-1.738771 -4.445663,-3.4355 -5.458977,-4.440385 -2.027679,-2.01081 -3.447083,-7.265561 -4.051243,-7.155492 z"
        fill="#b2a99c"
        id="path40"/>
      <path
        d="m 17.545008,27.68318 c 0.715231,1.050423 1.877111,2.403375 2.755649,3.171302 1.318457,1.152458 3.038783,2.35276 5.071669,3.85801 l 0.349391,-0.580823 c -1.785181,-1.420755 -4.361094,-2.984456 -5.792006,-4.780009 -1.648444,-2.06852 -2.693961,-5.806092 -3.060654,-5.457483 -0.489643,0.465497 -0.427449,2.168498 0.675951,3.789003 z"
        fill="#3f3737"
        id="path42"/>
      <path
        d="m 13.9765,24.30962 c -1.236697,0.483775 -0.193269,3.562504 0.257249,4.187287 1.302448,1.806249 3.444557,3.739315 5.124291,4.701706 1.791349,1.026341 3.87298,1.8965 6.321575,3.001484 l 0.08752,-1.369298 C 22.585747,32.807993 19.791895,30.655846 18.773096,29.725395 17.850322,28.882644 14.636382,24.051485 13.9765,24.30962 Z"
        fill="#b2a99c"
        id="path44"/>
      <path
        d="m 14.190873,25.443866 c -0.479427,0.543522 0.276773,2.417477 0.725873,2.980351 1.467365,1.839106 2.790273,3.290842 4.460429,4.26976 1.845222,1.081531 3.791754,2.054792 6.240349,3.159776 l -0.01347,-0.79717 c -2.591224,-1.497465 -6.499069,-4.343641 -7.252548,-5.214178 -1.273603,-1.471464 -3.804089,-4.80275 -4.160633,-4.398539 z"
        fill="#3f3737"
        id="path46"/>
      <path
        d="m 11.4291,29.035447 c 0.359625,1.961814 1.640234,3.075004 2.055434,3.409452 3.36693,2.712102 6.713923,3.206787 12.425281,4.729571 l -0.34599,-1.39495 C 23.353686,35.018304 19.908751,33.753668 17.677536,31.816664 12.746828,27.536126 11.06181,27.031821 11.4291,29.035447 Z"
        fill="#b2a99c"
        id="path48"/>
      <path
        d="m 12.418141,29.704656 c 0.142561,0.619997 0.862674,1.774645 1.680902,2.377317 3.583825,2.639697 5.84494,3.168814 11.556299,4.691598 l -0.255009,-0.774068 c -2.210139,-0.761215 -5.80102,-2.288852 -8.178499,-4.042463 -3.76347,-2.775908 -5.169787,-3.84452 -4.803693,-2.252384 z"
        fill="#3f3737"
        id="path50"/>
      <path
        d="m 16.433112,34.203227 c -5.244057,-2.93537 -6.361703,-1.919366 -5.088008,0.448265 0.349334,0.649366 1.2323,1.653569 2.39897,1.988074 2.64807,0.759251 7.442713,1.098458 12.91395,1.7691 L 25.90354,37.008388 C 22.117061,36.19652 18.00291,35.081924 16.433112,34.203227 Z"
        fill="#b2a99c"
        id="path52"/>
      <path
        d="m 16.460708,34.832221 c -3.488068,-1.50503 -5.422552,-2.237775 -4.381812,-0.524811 0.294177,0.484188 1.404209,1.672313 2.488575,1.91555 2.564173,0.575176 6.50198,1.373984 11.950041,1.931853 l -0.353145,-0.965551 c -3.786479,-0.811868 -7.893825,-1.576135 -9.703659,-2.357041 z"
        fill="#3f3737"
        id="path54"/>
      <path
        d="m 14.831415,40.755573 c 1.691578,0.115925 8.524392,0.158896 13.49651,-0.09859 l -0.338783,-2.240906 c -3.539731,-0.633471 -12.624755,-1.72044 -14.056381,-1.81739 -1.429957,-0.09684 -3.000693,0.03577 -1.938797,1.920198 0.58845,1.044256 1.405525,2.13856 2.837451,2.236691 z"
        fill="#b2a99c"
        id="path56"/>
      <path
        d="m 27.958033,38.728623 c -3.22845,-0.456057 -12.029545,-1.334407 -14.324281,-1.403037 -0.697889,-0.02087 -1.113299,0.404552 -0.74065,1.147065 0.466906,0.930322 1.255372,1.688223 2.286368,1.663573 1.603167,-0.03833 11.34995,-0.189104 12.938624,-0.271197 z"
        fill="#3f3737"
        id="path58"/>
      <path
        d="m 16.437501,44.031731 c 1.687279,-0.167225 7.197779,-1.198583 12.057696,-2.280002 l -0.72089,-2.260684 c -3.595792,-0.03551 -11.486974,0.448668 -12.914768,0.591339 -1.426131,0.142505 -2.952888,0.534687 -1.592173,2.216096 0.754041,0.931754 1.741848,1.874808 3.170135,1.733251 z"
        fill="#b2a99c"
        id="path60"/>
      <path
        d="m 28.260243,41.367424 -0.354596,-1.469847 c -3.30213,0.03355 -11.130205,0.583626 -13.263785,0.945251 -0.688383,0.116676 -1.160843,0.567823 -0.669813,1.23796 0.61523,0.839638 1.53941,1.682639 2.492969,1.289849 1.304112,-0.537188 10.242372,-1.657859 11.795225,-2.003213 z"
        fill="#3f3737"
        id="path62"/>
      <path
        d="m 17.912202,46.493106 c 1.65662,-0.361225 6.417414,-1.838564 11.119666,-3.474743 L 28.019633,40.70694 c -3.575773,0.380557 -10.729804,1.738967 -12.131521,2.045796 -1.400082,0.30647 -2.871243,0.87258 -1.325214,2.385351 0.856732,0.838304 1.94697,1.660797 3.349304,1.355019 z"
        fill="#b2a99c"
        id="path64"/>
      <path
        d="m 28.754048,42.663807 -0.39914,-1.576249 c -3.313566,0.462476 -10.457698,1.835674 -12.637058,2.363808 -0.678561,0.164439 -1.031996,0.770795 -0.466763,1.379652 0.708201,0.762858 1.710258,1.502674 2.611997,1.002247 1.23324,-0.684396 9.388467,-2.646844 10.890964,-3.169458 z"
        fill="#3f3737"
        id="path66"/>
      <path
        d="m 20.438314,48.805686 c 1.589629,-0.589878 4.708921,-2.049903 9.189669,-4.487959 l -1.162966,-2.156051 c -3.487124,0.878039 -9.210253,2.724014 -10.555119,3.2243 -1.343299,0.499703 -2.720579,1.266446 -0.97776,2.547564 0.965784,0.709932 2.160551,1.37148 3.506176,0.872146 z"
        fill="#b2a99c"
        id="path68"/>
      <path
        d="m 29.303205,44.005616 -0.589724,-1.438307 c -3.255061,0.903735 -8.904522,2.676478 -10.876519,3.567637 -0.63625,0.287526 -0.97582,0.799085 -0.330817,1.322698 0.808142,0.656053 2.171475,1.249504 2.994162,0.627616 1.125128,-0.850509 7.388498,-3.351575 8.802901,-4.079643 z"
        fill="#3f3737"
        id="path70"/>
      <path
        d="m 23.696152,50.842741 c 1.459512,-0.86296 3.355508,-2.235152 7.331715,-5.430617 l -1.643904,-1.801279 c -3.275604,1.483711 -7.549092,3.584596 -8.783656,4.315898 -1.233126,0.730449 -2.452241,1.729725 -0.509509,2.680764 1.076566,0.527018 2.369873,0.965733 3.605354,0.235234 z"
        fill="#b2a99c"
        id="path72"/>
      <path
        d="m 30.652797,45.162692 -0.79451,-1.201134 c -3.076377,1.275767 -7.418088,3.454558 -9.200347,4.681949 -0.575032,0.396009 -0.690976,1.000933 0.0368,1.401599 0.911859,0.502008 2.172564,0.778498 2.871652,0.02032 0.956089,-1.036904 5.823884,-3.934917 7.086403,-4.902732 z"
        fill="#3f3737"
        id="path74"/>
      <path
        d="m 27.26421,52.193618 c 1.286378,-1.104586 1.668244,-1.841695 5.173979,-5.547237 l -1.87316,-1.561506 c -3.04393,1.914542 -6.262509,3.962011 -7.3864,4.854099 -1.122581,0.891048 -2.238382,2.196406 -0.140966,2.72511 1.47785,0.372527 2.903451,0.665648 4.226547,-0.470466 z"
        fill="#b2a99c"
        id="path76"/>
      <path
        d="m 32.032737,46.45016 -0.950174,-1.082191 c -2.874769,1.681483 -6.114601,3.855621 -7.749556,5.273316 -0.415421,0.360218 -0.67155,1.079257 0.109039,1.363643 0.806888,0.293967 2.498343,0.498428 3.088064,-0.347615 0.806517,-1.157069 4.383139,-4.076946 5.502627,-5.207153 z"
        fill="#3f3737"
        id="path78"/>
      <path
        d="m 31.602959,52.994771 c 2.070252,-2.476287 2.960483,-4.050216 3.97011,-6.151589 l -2.740655,-0.925055 c -3.08544,2.141383 -5.976406,4.95941 -6.923994,6.741925 -0.556752,1.047312 0.214936,1.717744 2.039212,1.774417 1.609713,0.05 2.789264,-0.403776 3.655327,-1.439698 z"
        fill="#b2a99c"
        id="path80"/>
      <path
        d="m 35.105986,47.985937 -2.538931,-1.390635 c -2.509268,2.104794 -5.235414,4.640434 -5.763098,6.151961 -0.248792,0.712654 0.413705,0.905896 1.276144,0.903149 1.288696,-0.0041 3.143109,-0.494648 3.434585,-0.897032 0.75815,-1.046631 2.515364,-3.396762 3.5913,-4.767443 z"
        fill="#3f3737"
        id="path82"/>
      <path
        d="m 53.029364,33.803119 c -5.988085,1.436991 -8.564972,6.153318 -12.250776,8.665532 -3.068184,2.091249 -7.276754,5.877822 -9.323079,8.371527 -3.498482,4.263338 -8.737753,13.516275 -7.055104,13.516275 0.341912,0 1.11195,-0.794001 1.591961,-1.561847 L 58.973403,36.871368 Z"
        fill="#b2a99c"
        id="path84"/>
      <path
        d="m 58.541647,37.708291 -2.182297,1.682068 -1.270725,0.02842 c -1.610603,2.835515 -4.620405,2.056114 -12.55463,6.941714 -5.063032,3.117628 -13.74495,12.002061 -16.541784,16.434117 -0.272804,0.883412 -1.526884,5.305055 -0.53953,5.305055 1.293425,0 4.501218,-2.895711 6.439029,-4.124516 0.566463,0.427445 2.254721,0.53249 3.190492,-0.318958 0.477149,-0.434157 -0.01392,-1.482696 -0.110165,-1.803262 16.76459,-7.050572 15.390765,-8.572759 17.163799,-14.898177 0.870645,-2.577417 3.034966,-4.003071 4.649033,-5.528857 z"
        fill="#f5f3f2"
        id="path86"/>
      <path
        d="m 47.915453,45.732012 1.430114,0.852186 c 1.84881,-1.976602 3.93994,-4.797681 6.82118,-4.797681 0.477208,0 -2.004534,0.854745 -4.515232,5.128315 l 0.509422,0.03997 c 1.702604,-3.418247 4.453294,-5.066751 5.02891,-5.638217 -2.65823,-0.240845 -4.724019,0.660763 -9.274394,4.415421 z"
        id="path88"/>
      <path
        d="m 56.584392,39.466396 h -3.283257 l 1.920103,-4.119215 3.112209,2.255666 z"
        id="path90"/>
      <path
        d="m 56.514234,39.466396 c -0.113008,0.558426 -0.185102,1.195512 -0.04093,1.814886 2.470233,0.336009 2.901608,0.12453 4.084089,-0.263452 l -0.787947,-1.6458 z"
        fill="#dfcac1"
        id="path92"/>
      <path
        d="M 49.663263,41.788118 C 48.323532,41.23055 47.372368,40.107754 46.9392,38.92212"
        fill="none"
        stroke="#3f3737"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.264583"
        id="path94"/>
      <ellipse
        cx="65.875565"
        cy="-3.1006901"
        fill="#f5f3f2"
        rx="1.265977"
        ry="1.143875"
        transform="rotate(37.360717)"
        id="ellipse96"/>
      <ellipse
        cx="65.902489"
        cy="-3.0916831"
        fillOpacity="0.994995"
        rx="0.83001202"
        ry="0.74995798"
        transform="rotate(37.360717)"
        id="ellipse98"/>
      <path
        d="m 34.895506,61.613582 c -0.39539,0.112989 -0.80211,0.361619 -1.216213,0.698606"
        fill="none"
        id="path100"/>
      <g
        stroke="#b2a99c"
        strokeWidth="0.264583"
        id="g108"
        transform="translate(-8.473837,-1.533152)">
        <path
          d="m 40.256579,65.580799 c -0.261211,-0.401477 -0.609054,-1.516872 -0.523524,-3.019645 1.0382,-1.497949 2.4795,-2.496442 4.001397,-3.395067 0.49014,1.007995 -0.738529,2.719825 -0.770029,3.649123 0.320239,0.291827 0.568938,0.641918 0.700975,0.95805"
          fill="#f5f3f2"
          id="path102"/>
        <path
          d="m 39.733055,62.561154 c -0.29246,0.388825 -0.57452,0.802776 -0.7862,1.121317"
          fill="none"
          id="path104"/>
        <path
          d="m 43.734452,59.166087 c 0.34,-0.203561 0.61328,-0.317092 0.88633,-0.430326"
          fill="none"
          id="path106"/>
      </g>
      <circle
        cx="54.472782"
        cy="37.321655"
        fill="#ffffff"
        r="0.27681199"
        id="circle110"/>
      <path
        d="m 41.729435,42.471106 c -4.318448,-0.10139 -12.570394,-6.345399 -15.427026,-8.210795 -2.104192,-1.374049 -1.369304,3.208123 1.541973,6.464902 0.336036,2.161444 2.766928,6.037142 6.181467,7.373212 2.416042,-2.241586 7.438249,-2.811999 8.754607,-4.35969 0.179129,-0.210609 0.140896,-0.634527 -0.120509,-0.897842 -0.22212,-0.223742 -0.645656,-0.363099 -0.930512,-0.369787 z"
        fill="#524848"
        id="path112"/>
      <path
        d="m 56.514234,39.466396 2.298203,-1.955513 1.832262,3.626019 c -0.222315,0 -0.62618,-1.094902 -2.148528,-1.043074 -0.810499,0.02759 -1.951057,-0.193663 -1.981937,-0.627432 z"
        fill="#c8592b"
        id="path114"/>
      <path
        d="m 34.680564,62.117903 c 0.478241,0.796496 1.783974,0.843427 2.617688,0.503778 0.8824,-0.359483 -0.111877,-2.000945 -0.111877,-2.000945 z"
        fill="#b2a99c"
        id="path116"/>
      <path
        d="m 18.963095,79.907848 c 1.044248,-0.384136 2.381557,-1.45132 2.978497,-2.371426 0.51054,0.659 0.9348,0.807201 1.339837,0.807201 0.68415,0 1.158005,-0.475381 1.584728,-1.602591 -0.465268,0.923164 -1.018165,1.134651 -1.501615,1.162273 -0.474995,0.02714 -0.916874,-0.237026 -1.412355,-0.759267 -0.725748,0.847405 -2.25035,2.09253 -3.163439,2.205586 -0.506458,0.06271 -1.035848,-0.34826 -0.737821,-1.686189 -0.380778,0.912941 -0.937723,2.915321 0.912168,2.244413 z"
        fill="#b2a99c"
        id="path118"/>
      <path
        d="m 58.474384,36.613893 c -0.132054,1.214372 -1.102636,1.178308 -1.471495,1.178308 -1.69355,0 -1.807968,-2.733258 -3.948574,-3.989083 2.775041,-1.017716 7.221835,1.839615 6.193482,3.683157 -0.247387,-0.363053 -0.773413,-0.872382 -0.773413,-0.872382 z"
        fill="#3f3737"
        id="path120"/>
      <path
        d="m 53.30889,33.72197 c -0.08619,0.02371 -0.1714,0.05056 -0.254765,0.08113 2.111907,1.279345 2.004488,3.595653 3.653761,3.595653 0.323273,0 1.272534,-0.01495 1.554776,-0.74344 -0.309637,0.346578 -0.744892,0.367096 -1.274254,0.367096 -1.45623,0 -1.670468,-2.270363 -3.679518,-3.300444 z"
        fill="#f5f3f2"
        id="path122"/>
      <path
        d="m 56.514234,39.466396 c -0.02957,-1.528412 1.780044,-1.587113 1.96015,-2.852503 1.516264,1.345785 2.666122,4.523009 2.170315,4.523009 -0.222315,0 -0.627461,-1.198685 -2.149809,-1.146857 -0.810499,0.02759 -1.873342,-0.209882 -1.980656,-0.523649 z"
        fill="#ec8458"
        id="path124"/>
      <path
        d="m 58.72853,37.578194 -0.402287,0.376353 c 0.584537,0.575248 1.256768,1.362634 1.70542,1.990918 -0.418888,-1.074385 -0.82598,-1.734346 -1.303133,-2.367271 z"
        fill="#f9dbce"
        id="path126"/>
      <path
        d="m 58.851704,36.585878 c 0.154866,0.260496 0.343503,0.403141 0.536919,0.478526 0.01061,-0.08004 0.01306,-0.16101 0.0078,-0.24288 -0.153467,-0.0023 -0.303441,-0.05533 -0.544669,-0.235646 z"
        fill="#f5f3f2"
        id="path128"/>
      <path
        d="m 43.003059,58.504954 c -0.285162,-0.0021 -0.589084,0.05023 -0.903822,0.170532 l -5.144386,1.966291 c -0.801246,0.306252 -0.172633,1.916332 0.409792,1.62419 l 3.952732,-1.982825 c -0.38522,0.61355 -0.399314,1.498613 0.109038,2.41949 -0.08182,-1.159623 0.378375,-2.229678 1.300178,-2.858741 0.809172,0.196364 0.746003,1.689675 0.287322,2.574005 0.52361,-0.370099 0.872955,-0.667753 1.043344,-1.153936 -0.05392,0.749377 -0.533839,1.859529 -1.014407,2.41794 1.06875,-0.39484 1.526109,-1.374994 1.75545,-2.494939 0.742161,-1.070782 -0.255355,-2.670632 -1.795241,-2.682007 z"
        fill="#e0774a"
        id="path130"
        style={{
          strokeWidth: 0.264583
        }}/>
      <path
        d="m 40.690022,59.160213 c -0.346843,-0.0057 -0.718513,0.06792 -1.099675,0.243912 l -5.191413,2.39727 c -0.808622,0.373359 -0.04445,1.997067 0.538469,1.653646 l 3.955831,-2.330609 c -0.356021,0.662573 -0.308894,1.580227 0.282151,2.498556 -0.165912,-1.195454 0.236022,-2.335779 1.146702,-3.052008 0.852125,0.146664 0.891812,1.698066 0.478523,2.646349 0.516532,-0.420063 0.857453,-0.753062 0.999939,-1.268656 -0.0034,0.780103 -0.423354,1.963417 -0.882118,2.575552 1.07955,-0.483854 1.485157,-1.531051 1.644346,-2.707328 0.668197,-1.118235 -0.369769,-2.631834 -1.872755,-2.656684 z"
        fill="#ec8458"
        id="path132"
        style={{
          strokeWidth: 0.264583
        }}/>
    </g>
  </LogoRoot>
);

export default Lockup;
