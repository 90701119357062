import { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Grid,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import i18next from 'i18next';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const Agreement = ({ t }) => {
  const msg = t('contact-form:privacy-policy.agreement').split('{policy}');
  return (
    <Typography
      color="textSecondary"
      sx={{ mt: 3 }}
      variant="body2"
    >
      {msg[0]}
      <Link
        color="textPrimary"
        href="/privacy-policy"
        underline="always"
        variant="subtitle2"
      >
        {t('contact-form:privacy-policy.label')}
      </Link>
      {msg[1]}
    </Typography>
  );
};

Agreement.propTypes = {
  t: PropTypes.func,
};

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [text, setText] = useState('');
  const [status, setStatus] = useState(null);
  const i18n = useTranslation();

  const handleSubmit = (event) => {
    event.preventDefault();
    axios.post('/api/support/email', {
      name,
      email,
      text,
      lang: 'en',
    })
      .then(() => {
        setStatus('SUCCESS');
      })
      .catch((error) => {
        setStatus('ERROR');
        console.log(error);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          sm={12}
        >
          <Typography
            color="textPrimary"
            sx={{ mb: 1 }}
            variant="subtitle2"
          >
            {i18next.t('contact-form:textfield.name')}
            {' '}
            *
          </Typography>
          <TextField
            fullWidth
            name="name"
            required
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
        >
          <Typography
            color="textPrimary"
            sx={{ mb: 1 }}
            variant="subtitle2"
          >
            {i18next.t('contact-form:textfield.email')}
            {' '}
            *
          </Typography>
          <TextField
            fullWidth
            name="company"
            required
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Typography
            color="textPrimary"
            sx={{ mb: 1 }}
            variant="subtitle2"
          >
            {i18next.t('contact-form:textfield.your-message')}
            {' '}
            *
          </Typography>
          <TextField
            fullWidth
            name="message"
            required
            multiline
            rows={6}
            variant="outlined"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </Grid>
      </Grid>
      {status === 'ERROR' && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 3
          }}
        >
          <Alert severity="error" sx={{ width: '100%' }}>{i18next.t('contact-form:callback.error')}</Alert>
        </Box>
      )}
      {status === 'SUCCESS' && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 3
          }}
        >
          <Alert severity="success" sx={{ width: '100%' }}>{i18next.t('contact-form:callback.success')}</Alert>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 3
        }}
      >
        <Button
          color="primary"
          type="submit"
          fullWidth
          size="large"
          variant="contained"
          disabled={!text || !name || !email}
        >
          {i18next.t('contact-form:submit')}
        </Button>
      </Box>
      <Agreement t={i18n.t} />
    </form>
  );
};

export default ContactForm;
