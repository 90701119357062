const privacy = {
  title: 'Datenschutzerklärung',
  subtitle: 'Wir sind überzeugt davon ihre persönliche Privatsphäre schützen zu müssen.',
  content: `
# Datenschutzrechte
Wir möchten sicherstellen, dass Sie über alle Ihre Datenschutzrechte informiert sind. Jeder Nutzer hat Anspruch auf folgende Rechte.

**Auskunftsrecht** - Sie haben das Recht von uns Kopien, der bei uns gespeicherten personenbezogenen Daten, zu verlangen.

**Recht auf Berichtigung** -  Sie haben das Recht, von uns die Korrektur von Informationen zu verlangen, die Sie für unrichtig halten. Sie haben auch das Recht, die Vervollständigung von Informationen zu verlangen, die Sie für unvollständig halten.

**Recht auf Löschung und auf „Vergessenwerden“** -  Sie haben das Recht zu verlangen, dass wir Ihre personenbezogenen Daten löschen.

**Recht auf Einschränkung der Verarbeitung** - Sie haben das Recht zu verlangen, dass wir die Verarbeitung Ihrer personenbezogenen Daten einschränken.

**Recht auf Datenübertragbarkeit** -  Sie haben das Recht zu verlangen, dass wir die von uns erfassten Daten unter bestimmten Bedingungen an ein anderes Unternehmen oder direkt an Sie weitergeben.

Bitte kontaktieren Sie uns per [Email](mailto:support.staking@outofbits.com).

# Welche Information sammeln wir?
Wir sammeln auf dieser Webseite keine personenbezogenen Daten.

# Verwenden wir Cookies?
Nein, wir verwenden keine Cookies!

# Externe Links
Diese Webseite verlinkt externe Webseiten und betreibt zusätzlich einen Discord Server sowie Telegramkanal für unsere Delegatoren. Diese
Datenschutzerklärung bezieht sich jedoch nur auf diese Webseite, da wir keinen Einfluss auf Discord, Telegram oder die verlinkten
Webseiten haben.

# Aktualität der Datenschutzerklärung
Sie können die aktuelle Datenschutzerklärung jederzeit auf dieser Webseite finden. Die letzte Änderung wurde am 29. August 2021 durchgeführt.  
`,
};

export default privacy;
