import { darkShadows } from './shadows';

export default {
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
        }
      }
    }
  },
  palette: {
    background: {
      default: '#171c24',
      paper: '#222b36'
    },
    divider: 'rgba(145, 158, 171, 0.24)',
    error: {
      contrastText: '#ffffff',
      main: '#f44336'
    },
    mode: 'dark',
    primary: {
      contrastText: '#ffffff',
      main: '#f50056'
    },
    success: {
      contrastText: '#ffffff',
      main: '#4caf50'
    },
    text: {
      primary: '#ffffff',
      secondary: '#919eab',
    },
    warning: {
      contrastText: '#ffffff',
      main: '#ff9800'
    },
    leftOuterHelp: {
      contrastText: '#171c24',
      main: '#bb004e'
    },
    leftHelp: {
      contrastText: '#171c24',
      main: '#f50056'
    },
    rightHelp: {
      contrastText: '#171c24',
      main: '#f50056'
    },
    rightOuterHelp: {
      contrastText: '#171c24',
      main: '#bb004e'
    },
  },
  shadows: darkShadows
};
