import axios from 'axios';

class ScheduleApi {
  getRegisteredEpochs(limit) {
    return axios({
      url: 'api/leaderlog/epoch',
      method: 'get',
      params: {
        limit,
      }
    });
  }

  getSchedule(epoch, tz) {
    return axios({
      url: `api/leaderlog/epoch/${epoch}/by/date`,
      method: 'get',
      params: {
        tz,
      }
    });
  }

  getPerformance(epoch) {
    return axios({
      url: `api/leaderlog/epoch/${epoch}/performance`,
      method: 'get',
    });
  }

  getAssignedBlocksBeforeNow(epoch) {
    return axios({
      url: `api/leaderlog/epoch/${epoch}/blocks/before/now`,
      method: 'get',
    });
  }
}

export default new ScheduleApi();
