import { lightShadows } from './shadows';

export default {
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            opacity: 0.86,
            color: '#42526e'
          }
        }
      }
    }
  },
  palette: {
    action: {
      active: '#6b778c'
    },
    background: {
      default: '#f4f5f7',
      paper: '#ffffff'
    },
    error: {
      contrastText: '#ffffff',
      main: '#f44336'
    },
    mode: 'light',
    primary: {
      contrastText: '#ffffff',
      main: '#5664d2'
    },
    success: {
      contrastText: '#ffffff',
      main: '#4caf50'
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c'
    },
    warning: {
      contrastText: '#ffffff',
      main: '#ff9800'
    },
    leftOuterHelp: {
      contrastText: '#171c24',
      main: '#bb004e'
    },
    leftHelp: {
      contrastText: '#171c24',
      main: '#f50056'
    },
    rightHelp: {
      contrastText: '#171c24',
      main: '#f50056'
    },
    rightOuterHelp: {
      contrastText: '#171c24',
      main: '#bb004e'
    },
  },
  shadows: lightShadows
};
