import { Suspense, lazy } from 'react';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import PrivacyPolicyPost from './pages/blog/single/PrivacyPolicyPost';
import ContactUs from './pages/ContactUs';
import Imprint from './pages/Imprint';
import HomeLayout from './components/HomeLayout';
// import FaqPage from './pages/faq/FaqPage';
import DashboardPage from './pages/dashboard/DashboardPage';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Error pages
const NotFound = Loadable(lazy(() => import('./pages/error/NotFound')));
// Other pages
const Home = Loadable(lazy(() => import('./pages/Home')));

const routes = [
//  {
//    path: 'faq',
//    element: <MainLayout />,
//    children: [
//      {
//        path: '/',
//        element: <FaqPage />
//      }
//    ]
//  },
  {
    path: 'health',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <DashboardPage />
      }
    ]
  },
  {
    path: 'contact',
    element: <ContactUs />
  },
  {
    path: 'privacy-policy',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <PrivacyPolicyPost />
      }
    ]
  },
  {
    path: 'imprint',
    element: <Imprint />
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  },
  {
    path: '/',
    element: <HomeLayout />,
    children: [
      {
        path: '/',
        element: <Home />
      }
    ]
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
