import home from './home.json';
import footer from './footer.json';
import privacy from './privacy';
import contact from './contact.json';
import contactForm from './contact_form.json';
import imprint from './imprint.json';
import menu from './menu.json';
import error from './error.json';
import faq from './faq.json';

export default {
  home,
  error,
  footer,
  privacy,
  contact,
  menu,
  imprint,
  faq,
  'contact-form': contactForm,
};
