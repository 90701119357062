import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Divider, IconButton, Toolbar } from '@material-ui/core';
import { useState } from 'react';
import useSettings from '../hooks/useSettings';
import WbSunnyOutlinedIcon from '@material-ui/icons/WbSunnyOutlined';
import Brightness2OutlinedIcon from '@material-ui/icons/Brightness2Outlined';
import LanguagePopover from './LanguagePopover';
import HomeIcon from '@material-ui/icons/Home';

const MainNavbar = () => {
  const {
    settings,
    saveSettings
  } = useSettings();
  const [theme, setTheme] = useState(settings.theme);

  function handleThemeChange(mode) {
    setTheme(mode);
    saveSettings({ ...settings, theme: mode });
  }

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'background.paper',
        color: 'text.secondary'
      }}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <RouterLink to="/">
          <HomeIcon sx={{ color: 'text.secondary' }} />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <LanguagePopover color="inherit" />
        {theme === 'DARK' ? (
          <IconButton
            aria-label="light mode"
            color="inherit"
            onClick={() => handleThemeChange('LIGHT')}
          >
            <WbSunnyOutlinedIcon />
          </IconButton>
        ) : null}
        {theme === 'LIGHT' ? (
          <IconButton
            aria-label="dark mode"
            color="inherit"
            onClick={() => handleThemeChange('DARK')}
          >
            <Brightness2OutlinedIcon />
          </IconButton>
        ) : null}
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

export default MainNavbar;
