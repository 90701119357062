import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { fetchRegisteredEpochs } from '../../../../slices/epochList';

const mapToProps = (state) => ({ epochList: state.epochList ? state.epochList : {} });

function range(epoch, left, right) {
  const list = [];
  for (let i = (epoch - left); i <= (epoch + right); i++) {
    list.push(i);
  }
  return list;
}

const epochSelectionStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 'auto',
  marginLeft: 'auto',
  mb: 4,
};

function fetchEpochList(dispatch, status, timestamp) {
  if (!status || !timestamp || (status === 'FAILED' && new Date().getTime() - new Date(timestamp).getTime()) > 60000) {
    dispatch(fetchRegisteredEpochs(5));
  }
}

const EpochSelection = ({
  dispatch,
  epoch,
  epochList: {
    status,
    timestamp,
    list
  },
  onEpochSelection
}) => {
  const i18n = useTranslation();
  const [currentEpoch, setCurrentEpoch] = useState(epoch);
  const epochs = range(epoch, 3, 1);

  useEffect(() => {
    fetchEpochList(dispatch, status, timestamp);
  }, [status]);

  const handleChange = (event, nextEpoch) => {
    if (nextEpoch) {
      setCurrentEpoch(nextEpoch);
      if (onEpochSelection && list && list.includes(nextEpoch)) {
        onEpochSelection(nextEpoch);
      }
    }
  };

  return (
    <Box sx={epochSelectionStyle}>
      <Typography
        variant="h6"
        sx={{ mr: 2 }}
      >
        {i18n.t('dashboard:section.leader-log.label.epoch-selection')}
      </Typography>
      <ToggleButtonGroup
        orientation="horizontal"
        color="primary"
        value={currentEpoch}
        exclusive
        onChange={handleChange}
      >
        {epochs.map((cEpoch) => (
          <ToggleButton
            key={`select-${cEpoch}`}
            value={cEpoch}
            aria-label={`${cEpoch}`}
            disabled={!(list && list.includes(cEpoch))}
          >
            <Typography variant="h6">
              {cEpoch}
            </Typography>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
};

EpochSelection.propTypes = {
  dispatch: PropTypes.func,
  epoch: PropTypes.number,
  epochList: PropTypes.any,
  onEpochSelection: PropTypes.func,
};

export default connect(mapToProps)(EpochSelection);
