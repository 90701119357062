import { useEffect, useState } from 'react';
import { Box, Container } from '@material-ui/core';
import EpochOverview from './epoch/EpochOverview';
import EpochProgressBar from './epoch/EpochProgressBar';
import DashSection from './sections/DashSection';
import ScheduleTable from './items/schedule/ScheduleTable';
import { ConcreteSlotDate, MainNetworkSetting } from 'cardano-time';
import { useTranslation } from 'react-i18next';

function prepareSlotDate(slotDate) {
  if (slotDate) {
    const params = MainNetworkSetting.getSettingsFor(slotDate.getEpoch());
    return {
      epoch: slotDate.getEpoch(),
      slot: slotDate.getSlot(),
      startTime: new ConcreteSlotDate(slotDate.getEpoch(), 0, MainNetworkSetting).getStartTime(),
      endTime: new ConcreteSlotDate(slotDate.getEpoch(), params.epochLength - 1, MainNetworkSetting).getEndTime(),
      progress: Math.trunc((10000 * slotDate.getSlot()) / params.epochLength) / 100,
    };
  }
  return null;
}

const HealthBoard = () => {
  const i18n = useTranslation();
  const [slotDate, setSlotDate] = useState(null);

  useEffect(() => {
    setSlotDate(MainNetworkSetting.getSlotDateOfTime(new Date()));
  }, []);

  useEffect(() => {
    if (slotDate) {
      const newTimer = setTimeout(() => {
        setSlotDate(slotDate.add(1));
      }, Math.max(slotDate.add(1)
        .getStartTime() - Date.now(), 0));
      return () => clearTimeout(newTimer);
    }
    return null;
  }, [slotDate]);

  const slotObj = prepareSlotDate(slotDate);
  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        pb: {
          xs: 4,
          sm: 8,
          md: 16,
        },
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            py: {
              xs: 4,
              sm: 4,
              md: 6,
              lg: 8
            },
          }}
        >
          <EpochOverview
            epoch={slotObj ? slotObj.epoch : null}
            slot={slotObj ? slotObj.slot : null}
            startTime={slotObj ? slotObj.startTime : null}
            endTime={slotObj ? slotObj.endTime : null}
          />
          <EpochProgressBar
            progress={slotObj ? slotObj.progress : null}
            endTime={slotObj ? slotObj.endTime : null}
            sx={{
              my: 2,
            }}
          />
        </Box>
        <DashSection
          title={i18n.t('dashboard:section.title.block-schedule')}
          component={slotObj ? (
            <ScheduleTable
              initialEpoch={slotObj.epoch}
            />
          ) : null}
        />
      </Container>
    </Box>
  );
};

export default HealthBoard;
