export function prepareLink(detail) {
  if (!detail) {
    return null;
  }
  const revBlock = detail.RelevantBlock;
  if (!revBlock || !revBlock.Height) {
    return null;
  }
  return `https://cardanoscan.io/block/${revBlock.Height}`;
}
