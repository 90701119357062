import { Box, Divider, Table, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { eachDayOfInterval, format } from 'date-fns';
import BlockScheduleContent from './BlockScheduleContent';
import EpochSelection from './EpochSelection';
import { useState } from 'react';
import { ConcreteSlotDate, MainNetworkSetting } from 'cardano-time';
import EpochPerformanceOverview from '../../EpochPerformanceOverview';

function getRangeOf(startTime, endTime) {
  return eachDayOfInterval({
    start: startTime,
    end: endTime
  });
}

const ScheduleTable = ({
  initialEpoch
}) => {
  const [epoch, setEpoch] = useState(initialEpoch);
  const params = MainNetworkSetting.getSettingsFor(epoch);
  const startTime = new ConcreteSlotDate(epoch, 0, MainNetworkSetting).getStartTime();
  const endTime = new ConcreteSlotDate(epoch, params.epochLength - 1, MainNetworkSetting).getEndTime();
  const days = getRangeOf(startTime, endTime);

  const handleEpochChange = (nextEpoch) => {
    setEpoch(nextEpoch);
  };

  return (
    <Box>
      <EpochSelection
        epoch={initialEpoch}
        onEpochSelection={handleEpochChange}
      />
      <EpochPerformanceOverview epoch={epoch} />
      <Table sx={{ mt: 5, mb: 5 }}>
        <TableHead>
          <TableRow>
            {days && days.map((day) => (
              <TableCell key={`header-day-${day}`}>
                <Typography variant="h6">
                  {format(day, 'E (do LLL)')}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <BlockScheduleContent
          epoch={epoch}
          days={days}
        />
      </Table>
      <Divider />
    </Box>
  );
};

ScheduleTable.propTypes = {
  initialEpoch: PropTypes.number
};

export default ScheduleTable;
