import PropTypes from 'prop-types';
import MintedBlockLabel from './MintedBlockLabel';
import { Avatar } from '@material-ui/core';

const BlockSwitch = ({
  no,
  blockDetail,
  sx
}) => {
  if (blockDetail) {
    return (
      <MintedBlockLabel
        no={no}
        detail={blockDetail}
        sx={sx}
      />
    );
  }
  return (
    <Avatar sx={sx}>
      {no}
    </Avatar>
  );
};

BlockSwitch.propTypes = {
  no: PropTypes.number,
  blockDetail: PropTypes.any,
  sx: PropTypes.any,
};

export default BlockSwitch;
