import i18n from 'i18next';
import numeral from 'numeral';
import 'numeral/locales';
import { initReactI18next } from 'react-i18next';

import en from './translations/en';
import de from './translations/de';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en,
      de
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        numeral.locale(lng ? lng.substr(0, 2) : 'en');
        switch (format) {
          case 'coin':
            return numeral(value)
              .format('(0.00a)');
          case 'percentage':
            return numeral(value)
              .format('0.00%');
          case 'abs-percentage':
            return numeral(value)
              .format('0%');
          case 'money':
            return numeral(value)
              .format('(0a)');
          default:
            return value;
        }
      }
    }
  });
