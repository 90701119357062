import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Grid, Box, Container, Typography } from '@material-ui/core';
import HealthBoard from '../../components/analytics/HealthBoard';

const DashboardPage = () => {
  const i18n = useTranslation();
  return (
    <>
      <Helmet>
        <title>
          {i18n.t('dashboard:title')}
          {' | '}
          SOBIT
        </title>
      </Helmet>
      { /* --- Header Element --- */}
      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 15
        }}
      >
        <Container maxWidth="lg">
          <Grid
            alignItems="center"
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <Typography
                color="textPrimary"
                variant="h1"
              >
                {i18n.t('dashboard:title')}
              </Typography>
              <Typography
                color="textSecondary"
                sx={{ mt: 1 }}
                variant="body1"
              >
                {i18n.t('dashboard:subtitle')}
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              sx={{
                display: {
                  md: 'flex',
                  xs: 'none'
                },
                justifyContent: 'center'
              }}
            >
              <img
                width="25%"
                alt="Components"
                src="/static/browse/heartbeat.svg"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <HealthBoard />
    </>
  );
};

export default DashboardPage;
