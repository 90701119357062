import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import poolApi from '../api/poolApi';

export const fetchPoolInfo = createAsyncThunk(
  'pool/fetchInfo',
  async () => {
    const response = await poolApi.getPoolInfo();
    return response.data;
  }
);

const slice = createSlice({
  name: 'pool',
  initialState: {
    saturation: null,
    delegatorCount: null,
    status: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPoolInfo.pending, (state) => {
        state.status = 'PENDING';
        state.timestamp = new Date();
      })
      .addCase(fetchPoolInfo.rejected, (state) => {
        state.status = 'FAILED';
        state.timestamp = new Date();
      })
      .addCase(fetchPoolInfo.fulfilled, (state, action) => {
        const respObj = action.payload.response;
        state.status = 'LOADED';
        state.timestamp = new Date();
        state.saturation = respObj.StakeShare;
        state.delegatorCount = respObj.DelegatorCount;
      });
  },
});

export const { reducer } = slice;

export default slice;
