import { combineReducers } from '@reduxjs/toolkit';
import { reducer as poolReducer } from '../slices/pool';
import { reducer as epochReducer } from '../slices/epochList';
import { reducer as scheduleReducer } from '../slices/schedule';
import { reducer as performanceReducer } from '../slices/performance';
import { reducer as blockDetailsReducer } from '../slices/blockdetails';

const rootReducer = combineReducers({
  pool: poolReducer,
  epochList: epochReducer,
  schedule: scheduleReducer,
  performance: performanceReducer,
  blockDetails: blockDetailsReducer,
});

export default rootReducer;
