import { Avatar, Box, Card, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FolderOpenIcon from '../../../../icons/FolderOpen';
import { alpha } from '@material-ui/core/styles';

const AssignedBlocksItem = ({
  assignedBlocks,
  lg,
  md,
  sm,
  xs
}) => {
  const i18n = useTranslation();
  let b = '-';
  if (assignedBlocks) {
    b = assignedBlocks;
  }
  return (
    <Grid
      item
      xs={xs}
      md={md}
      sm={sm}
      lg={lg}
    >
      <Card
        sx={{
          background: (theme) => alpha(theme.palette.background.default, 0.5),
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          p: 3
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="overline"
          >
            {i18n.t('dashboard:section.leader-log.label.assigned-blocks')}
          </Typography>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap'
            }}
          >
            <Typography
              color="textPrimary"
              sx={{ mr: 1 }}
              variant="h5"
            >
              {b}
            </Typography>
          </Box>
        </Box>
        <Avatar
          sx={{
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
            height: 48,
            width: 48
          }}
        >
          <FolderOpenIcon fontSize="small" />
        </Avatar>
      </Card>
    </Grid>
  );
};

AssignedBlocksItem.propTypes = {
  assignedBlocks: PropTypes.number,
  lg: PropTypes.any,
  md: PropTypes.any,
  sm: PropTypes.any,
  xs: PropTypes.any
};

export default AssignedBlocksItem;
