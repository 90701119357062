import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import scheduleApi from '../api/scheduleApi';

export const fetchAssignedBlockDetails = createAsyncThunk(
  'epochList/fetchBlockDetails',
  async (epoch) => {
    const response = await scheduleApi.getAssignedBlocksBeforeNow(epoch);
    return response.data;
  }
);

const slice = createSlice({
  name: 'blockDetails',
  initialState: {
    status: {},
    details: {}
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssignedBlockDetails.pending, (state, action) => {
        const epoch = action.meta.arg;
        state.status[epoch] = {
          type: 'PENDING',
          timestamp: new Date()
        };
      })
      .addCase(fetchAssignedBlockDetails.rejected, (state, action) => {
        const epoch = action.meta.arg;
        state.status[epoch] = {
          type: 'FAILED',
          timestamp: new Date()
        };
      })
      .addCase(fetchAssignedBlockDetails.fulfilled, (state, action) => {
        const epoch = action.meta.arg;
        const respObj = action.payload;
        if (respObj.status === 'ok') {
          state.status[epoch] = {
            type: 'LOADED',
            timestamp: respObj.timestamp
          };
          state.details[epoch] = {};
          if (respObj.response) {
            for (let i = 0; i < respObj.response.length; i++) {
              const block = respObj.response[i];
              state.details[epoch][block.No] = block;
            }
          }
        } else {
          state.status[epoch] = {
            type: 'FAILED',
            timestamp: respObj.timestamp
          };
        }
      });
  },
});

export const { reducer } = slice;

export default slice;
