import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import scheduleApi from '../api/scheduleApi';

export const fetchRegisteredEpochs = createAsyncThunk(
  'epochList/fetchRegisteredEpochs',
  async (limit) => {
    const response = await scheduleApi.getRegisteredEpochs(limit);
    return response.data;
  }
);

const slice = createSlice({
  name: 'epochList',
  initialState: { status: 'PENDING' },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegisteredEpochs.pending, (state) => {
        state.status = 'PENDING';
        state.timestamp = new Date();
      })
      .addCase(fetchRegisteredEpochs.rejected, (state) => {
        state.status = 'FAILED';
        state.timestamp = new Date();
      })
      .addCase(fetchRegisteredEpochs.fulfilled, (state, action) => {
        const respObj = action.payload;
        if (respObj.status === 'ok') {
          state.status = 'LOADED';
          state.timestamp = respObj.timestamp;
          state.list = respObj.response;
        } else {
          state.status = 'FAILED';
          state.timestamp = respObj.timestamp;
        }
      });
  },
});

export const { reducer } = slice;

export default slice;
