import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import scheduleApi from '../api/scheduleApi';

export const fetchSchedule = createAsyncThunk(
  'epochList/fetchSchedule',
  async ({ epoch, tz }) => {
    const response = await scheduleApi.getSchedule(epoch, tz);
    return response.data;
  }
);

const slice = createSlice({
  name: 'schedule',
  initialState: { status: {}, assignments: {} },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSchedule.rejected, (state, action) => {
        const { epoch } = action.meta.arg;
        state.status[epoch] = { type: 'FAILED', timestamp: new Date() };
      })
      .addCase(fetchSchedule.fulfilled, (state, action) => {
        const { epoch } = action.meta.arg;
        const respObj = action.payload;
        if (respObj.status === 'ok') {
          state.status[epoch] = { type: 'LOADED', timestamp: respObj.timestamp };
          state.assignments[epoch] = respObj.response;
        } else {
          state.status[epoch] = { type: 'FAILED', timestamp: respObj.timestamp };
        }
      });
  },
});

export const { reducer } = slice;

export default slice;
