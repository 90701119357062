import PropTypes from 'prop-types';
import { Avatar, Link } from '@material-ui/core';
import { prepareLink } from './utils';

const MintedBlockLabel = ({
  no,
  detail,
  sx
}) => {
  let lProps = { href: null, underline: 'none' };
  const l = prepareLink(detail);
  if (l) {
    lProps = { ...lProps, href: l, target: '_blank' };
  }
  if (detail && detail.Status) {
    switch (detail.Status) {
      case 1:
        sx = { ...sx, bgcolor: ((theme) => theme.palette.success.main) };
        break;
      case 2:
        sx = { ...sx, bgcolor: ((theme) => theme.palette.warning.main) };
        break;
      case 3:
        sx = { ...sx, bgcolor: ((theme) => theme.palette.error.main) };
        break;
      case 4:
        sx = { ...sx, bgcolor: ((theme) => theme.palette.error.main) };
        break;
      default:
        break;
    }
  }
  return (
    <Link
      {...lProps}
    >
      <Avatar
        sx={sx}
      >
        {no}
      </Avatar>
    </Link>
  );
};

MintedBlockLabel.propTypes = {
  no: PropTypes.number,
  detail: PropTypes.any,
  sx: PropTypes.any,
};

export default MintedBlockLabel;
