export const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
};

export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID
};

export const blockfrost = {
  apiURL: 'https://cardano-mainnet.blockfrost.io/api/v0',
  token: 'kiZZfJe3phdjnAPMNDu38NyJIm4ABuL5',
};

export const sobit = {
  poolID: 'cdae4a1a08974113e77ea332cb1da97d9e3fca5cf797f9394739214b',
  margin: 0.00,
  fixedCost: 340,
  pledge: 210000,
  officePosition: [48.189637, 16.3446575],
  customers: 2500,
  business: {
    registered: true,
    name: 'Blockblu IO e.U.',
    email: 'office@blockblu.io',
    telephoneNumber: {
      human: '+43 670 6055022',
      machine: '00436706055022'
    },
    uid: 'ATU77269723',
    companyRegNumber: '566942p',
    companyRegCourt: {
      name: 'Handelsgericht Wien',
      link: 'https://www.justiz.gv.at/hg-wien/handelsgericht-wien~26d.de.html'
    },
    regulatoryAuthority: {
      name: 'Magistratisches Bezirksamt des VI. Bezirks'
    }
  }
};
