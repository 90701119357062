import { Box, LinearProgress, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { formatDistance } from 'date-fns';
import { useTranslation } from 'react-i18next';

const linearProgressStyle = {
  height: 75,
  width: '100%',
  color: 'success.main',
};

const barLabel = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translateX(-50%) translateY(-50%)',
  textAlign: 'center',
};

const EpochProgressBar = ({
  progress,
  endTime,
  sx
}) => {
  const i18n = useTranslation();
  let duration = null;
  if (endTime !== null) {
    duration = formatDistance(new Date(), endTime, { includeSeconds: true });
  }
  return (
    <Box sx={{ ...sx,
      width: '100%',
      position: 'relative',
      color: 'primary.contrastText'
    }}
    >
      <LinearProgress
        variant="determinate"
        value={progress !== null ? progress : 0}
        style={linearProgressStyle}
      />
      <Box sx={barLabel}>
        <Typography
          variant="h5"
          component="span"
        >
          {duration && i18n.t('dashboard:section.progress.label.distance', { duration })}
        </Typography>
      </Box>
    </Box>
  );
};

EpochProgressBar.propTypes = {
  progress: PropTypes.number,
  endTime: PropTypes.instanceOf(Date),
  sx: PropTypes.any,
};

export default EpochProgressBar;
