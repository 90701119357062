import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { fetchSchedule } from '../../../../slices/schedule';
import BlockSwitch from './block/BlockSwitch';
import { useEffect } from 'react';
import { fetchAssignedBlockDetails } from '../../../../slices/blockdetails';

const mapToProps = (state) => ({
  schedule: state.schedule ? state.schedule : {},
  blockDetails: state.blockDetails ? state.blockDetails : {},
});

function fetchS(dispatch, epoch, status) {
  if (!status || !(epoch in status) || !(status[epoch].timestamp) || (status[epoch].type === 'FAILED'
    && (new Date().getTime() - new Date(status[epoch].timestamp)) > 60000)) {
    dispatch(fetchSchedule({ epoch,
      tz: Intl.DateTimeFormat()
        .resolvedOptions().timeZone
    }));
  }
}

function fetchD(dispatch, epoch, status) {
  if (!status || !(epoch in status) || !(status[epoch].timestamp) || (status[epoch].type !== 'FAILED'
    && (new Date().getTime() - new Date(status[epoch].timestamp)) > 60000)) {
    dispatch(fetchAssignedBlockDetails(epoch));
  }
}

function maxAssignmentsPerDay(assignments) {
  let max = 0;
  const keys = Object.keys(assignments);
  for (let i = 0; i < keys.length; i++) {
    const l = assignments[keys[i]];
    if (l && max < l.length) {
      max = l.length;
    }
  }
  return max;
}

function prepareBlockMap(epochAssignments) {
  const obj = {};
  const keys = Object.keys(epochAssignments);
  for (let i = 0; i < keys.length; i++) {
    obj[new Date(keys[i]).getTime()] = epochAssignments[keys[i]];
  }
  return obj;
}

function constructTable(epoch, days, epochAssignments, details) {
  const rows = [];
  const maxSize = maxAssignmentsPerDay(epochAssignments);
  const blockMap = prepareBlockMap(epochAssignments);
  for (let i = 0; i < maxSize; i++) {
    rows.push((
      <TableRow key={`row-entry-${i}`}>
        {days.map((day) => {
          const d = day.getTime();
          return (
            <TableCell
              key={`row-entry-${i}-${d}`}
              sx={{ textAlign: 'center', borderBottom: 'none' }}
            >
              {blockMap[d] && i < blockMap[d].length ? (
                <BlockSwitch
                  epoch={epoch}
                  no={blockMap[d][i]}
                  blockDetail={details[blockMap[d][i]]}
                  sx={{ marginLeft: 'auto', marginRight: 'auto' }}
                />
              ) : null}
            </TableCell>
          );
        })}
      </TableRow>
    ));
  }
  return rows;
}

const BlockScheduleContent = ({
  dispatch,
  epoch,
  days,
  schedule: {
    status: scheduleStatus,
    assignments
  },
  blockDetails: {
    status: detailStatus,
    details
  }
}) => {
  useEffect(() => {
    fetchS(dispatch, epoch, scheduleStatus);
  }, [epoch, scheduleStatus]);
  useEffect(() => {
    fetchD(dispatch, epoch, detailStatus);
  }, [epoch, scheduleStatus]);
  const epochAssignments = assignments[epoch];
  return (
    <TableBody>
      {epochAssignments && constructTable(epoch, days, epochAssignments, details && epoch in details ? details[epoch] : {})}
    </TableBody>
  );
};

BlockScheduleContent.propTypes = {
  dispatch: PropTypes.func,
  epoch: PropTypes.number,
  days: PropTypes.array,
  schedule: PropTypes.any,
  blockDetails: PropTypes.any,
};

export default connect(mapToProps)(BlockScheduleContent);
