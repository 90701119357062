import { Avatar, Box, Card, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CurrencyDollarIcon from '../../../../icons/CurrencyDollar';
import { alpha } from '@material-ui/core/styles';
import { green, deepOrange } from '@material-ui/core/colors';

function computeColorForPerformance(performance) {
  if (performance < 0.05) {
    return (theme) => alpha(theme.palette.background.default, 0.5);
  }
  if (performance <= 0.95) {
    const strength = 1 - performance / 0.95;
    return () => alpha(deepOrange[(1 + Math.ceil(strength * 0.8 * 10)) * 100], 0.5);
  }
  if (performance > 1.05) {
    const strength = (Math.min(performance, 2.0) - 1.05) / 0.95;
    return () => alpha(green[(1 + Math.ceil(strength * 0.8 * 10)) * 100], 0.5);
  }
  return (theme) => alpha(theme.palette.primary.main, 0.5);
}

const MaxPerformanceItem = ({
  maxPerformance,
  lg,
  md,
  sm,
  xs
}) => {
  const i18n = useTranslation();
  return (
    <Grid
      item
      xs={xs}
      md={md}
      sm={sm}
      lg={lg}
    >
      <Card
        sx={{
          alignItems: 'center',
          backgroundColor: computeColorForPerformance(maxPerformance),
          color: 'primary.contrastText',
          display: 'flex',
          justifyContent: 'space-between',
          p: 3
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            color="inherit"
            gutterBottom
            variant="overline"
          >
            {i18n.t('dashboard:section.leader-log.label.max-performance')}
          </Typography>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap'
            }}
          >
            <Typography
              color="inherit"
              sx={{ mr: 1 }}
              variant="h5"
            >
              {Math.round(maxPerformance * 10000) / 100}
              %
            </Typography>
          </Box>
        </Box>
        <Avatar
          sx={{
            backgroundColor: 'primary.contrastText',
            color: 'primary.main',
            height: 48,
            width: 48
          }}
        >
          <CurrencyDollarIcon fontSize="small" />
        </Avatar>
      </Card>
    </Grid>
  );
};

MaxPerformanceItem.propTypes = {
  maxPerformance: PropTypes.number,
  lg: PropTypes.any,
  md: PropTypes.any,
  sm: PropTypes.any,
  xs: PropTypes.any
};

export default MaxPerformanceItem;
