import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Card, Grid, Typography } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';

const MintedBlocksItem = ({
  mintedBlocks,
  lg,
  md,
  sm,
  xs
}) => {
  const i18n = useTranslation();
  return (
    <Grid
      item
      lg={lg}
      md={md}
      sm={sm}
      xs={xs}
      sx={{
        textAlign: 'center'
      }}
    >
      <Card
        sx={{
          background: (theme) => alpha(theme.palette.background.default, 0.5),
          p: 3
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Typography
            color={mintedBlocks ? 'success.main' : 'textPrimary'}
            component="span"
            variant="h4"
          >
            {mintedBlocks ? (`+${mintedBlocks}`) : '-'}
          </Typography>
        </Box>
        <Typography
          color="textSecondary"
          sx={{ mt: 1 }}
          variant="overline"
        >
          {i18n.t('dashboard:section.leader-log.label.minted-blocks')}
        </Typography>
      </Card>
    </Grid>
  );
};

MintedBlocksItem.propTypes = {
  mintedBlocks: PropTypes.number,
  lg: PropTypes.any,
  md: PropTypes.any,
  sm: PropTypes.any,
  xs: PropTypes.any
};

export default MintedBlocksItem;
