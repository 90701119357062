import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import scheduleApi from '../api/scheduleApi';

export const fetchPerformance = createAsyncThunk(
  'epochList/fetchPerformance',
  async (epoch) => {
    const response = await scheduleApi.getPerformance(epoch);
    return response.data;
  }
);

const slice = createSlice({
  name: 'performance',
  initialState: { status: {}, result: {} },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPerformance.pending, (state, action) => {
        const epoch = action.meta.arg;
        state.status[epoch] = { type: 'PENDING', timestamp: new Date() };
      })
      .addCase(fetchPerformance.rejected, (state, action) => {
        const epoch = action.meta.arg;
        state.status[epoch] = { type: 'FAILED', timestamp: new Date() };
      })
      .addCase(fetchPerformance.fulfilled, (state, action) => {
        const epoch = action.meta.arg;
        const respObj = action.payload;
        if (respObj.status === 'ok') {
          state.status[epoch] = { type: 'LOADED', timestamp: respObj.timestamp };
          state.result[epoch] = respObj.response;
        } else {
          state.status[epoch] = { type: 'FAILED', timestamp: respObj.timestamp };
        }
      });
  },
});

export const { reducer } = slice;

export default slice;
