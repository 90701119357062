import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import Footer from './Footer';
import HomeNavbar from './HomeNavbar';

const HomeLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  height: '100%',
  paddingTop: 64
}));

const HomeLayout = ({ children }) => (
  <HomeLayoutRoot>
    <HomeNavbar />
    {children || <Outlet />}
    <Footer />
  </HomeLayoutRoot>
);

HomeLayout.propTypes = {
  children: PropTypes.node
};

export default HomeLayout;
