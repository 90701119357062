import axios from 'axios';

class PoolApi {
  getPoolInfo() {
    return axios({
      url: 'api/pool/info',
      method: 'get'
    });
  }
}

export default new PoolApi();
