import { experimentalStyled } from '@material-ui/core/styles';

const LogoRoot = experimentalStyled('svg')``;

const Logo = (props) => (
  <LogoRoot
    height={250}
    width={250}
    version="1.1"
    viewBox="0 0 66.146 66.146"
    {...props}
  >
    <title>SOBIT</title>
    <circle cx={33.073} cy={31.189} r={28.849} fill="#f50057" />
    <g transform="translate(-6.038 -16.249)">
      <path
        d="M45.838 44.377c-1.828-1.798-3.336-3.075-4.638-4.056-1.557-1.172-2.14-1.085-2.523-.34-.366.712-.348 1.825.703 2.584 1.102.797 3.136 2.534 5.812 4.237z"
        fill="#b2a99c"
      />
      <path
        d="M45.712 44.726c-1.891-1.656-3.435-3.01-4.739-3.988-.998-.748-1.61-.872-1.82-.414-.238.517-.085 1.334.606 1.947 1.018.902 2.909 2.402 5.584 4.105z"
        fill="#3f3737"
      />
      <path
        d="M46.633 42.547c-1.656-1.956-3.042-3.366-4.249-4.46-1.443-1.31-2.032-1.277-2.482-.57-.429.676-.512 1.785.465 2.638 1.025.893 2.893 2.809 5.402 4.749z"
        fill="#b2a99c"
      />
      <path
        d="M46.476 42.884c-1.733-1.822-3.146-3.311-4.356-4.404-.926-.836-1.524-1.015-1.775-.578-.284.493-.206 1.32.427 1.994.93.991 2.677 2.657 5.186 4.597z"
        fill="#3f3737"
      />
      <path
        d="M47.59 41.12c-1.466-2.103-2.714-3.636-3.813-4.839-1.314-1.439-1.903-1.46-2.417-.798-.491.632-.678 1.729.215 2.669.937.985 2.617 3.067 4.934 5.234z"
        fill="#b2a99c"
      />
      <path
        d="M47.402 41.441c-1.554-1.976-2.823-3.591-3.925-4.792-.843-.92-1.422-1.154-1.713-.743-.328.465-.328 1.296.239 2.026.834 1.074 2.416 2.896 4.733 5.063z"
        fill="#3f3737"
      />
      <path
        d="M48.667 39.349c-1.306-2.206-2.385-3.909-3.392-5.19-1.204-1.533-1.764-1.384-2.3-.883-.585.546-.874 1.774-.053 2.778.86 1.052 2.349 3.141 4.498 5.474z"
        fill="#b2a99c"
      />
      <path
        d="M48.35 39.836c-1.306-2.206-2.282-3.896-3.274-5.19-.805-1.05-1.295-1.455-1.706-.842-.411.614-.537 1.396.284 2.4.86 1.052 1.988 2.468 4.041 4.92z"
        fill="#3f3737"
      />
      <path
        d="M49.587 37.884c-.989-2.365-1.732-3.877-2.552-5.285-.981-1.684-1.572-1.937-2.203-1.309-.568.565-.947 1.747-.272 2.854.707 1.161 1.685 3.12 3.492 5.726z"
        fill="#b2a99c"
      />
      <path
        d="M49.307 38.19c-.99-2.365-1.676-3.826-2.51-5.226-.75-1.258-1.133-1.55-1.578-1.138-.414.383-.7 1.134-.124 2.274.613 1.213 1.46 2.797 3.267 5.404z"
        fill="#3f3737"
      />
      <path
        d="M50.3 35.714c-.548-2.504-1.037-4.254-1.591-5.787-.663-1.833-1.423-2.313-2.075-1.867-.661.451-1.16 1.916-.696 3.127.488 1.27 1.152 3.37 2.461 6.26z"
        fill="#b2a99c"
      />
      <path
        d="M50.066 35.882c-.47-2.151-1.262-4.133-1.738-5.45-.569-1.574-.935-2.108-1.496-1.725-.567.388-.817 1.534-.418 2.574.418 1.09 1.125 3.404 2.25 5.885z"
        fill="#3f3737"
      />
      <path
        d="M51.599 34.388c-.069-2.563-.221-4.373-.478-5.983-.307-1.924-.802-2.46-1.566-2.196-.757.262-1.644 1.643-1.415 2.919.241 1.338.5 3.526 1.244 6.61z"
        fill="#b2a99c"
      />
      <path
        d="M51.308 34.632c-.068-2.563-.31-4.47-.61-6.072-.184-.98-.54-1.866-.947-1.698-.618.255-1.376 1.248-1.237 2.33.17 1.32.549 3.228 1.225 6.35z"
        fill="#3f3737"
      />
      <path
        d="M53.152 34.055c.522-2.51.79-4.307.91-5.932.142-1.944-.077-2.775-.845-2.545-.767.23-2.086 1.319-2.156 2.613-.073 1.358-.324 3.547-.308 6.719z"
        fill="#b2a99c"
      />
      <path
        d="M52.759 34.313c.521-2.51.78-4.595.883-6.125.065-.95.057-2.07-.535-1.926-.778.19-1.546 1.146-1.645 2.214-.125 1.354-.311 3.27-.295 6.443zM26.499 62.02l-2.294 2.163c-1.227 2.843-9.757 15.872-5.94 15.872 1.043 0 2.934-1.373 3.677-2.519.51.66.934.808 1.34.808 1.717 0 2.11-2.998 3.759-11.787z"
        fill="#3f3737"
      />
      <path
        d="M30.42 37.74s-1.938-2.224-.407-2.224c1.036 0 1.798 2.926 3.974 4.312z"
        fill="#524848"
      />
      <path
        d="M16.792 22.664c-.658.12-.949 2.624-.359 3.99 1.005 1.98 2.613 3.573 3.716 4.435 1.7 1.328 3.065 2.317 5.6 4.187l.553-1.016c-2.397-1.738-4.445-3.435-5.459-4.44-2.027-2.01-3.447-7.266-4.05-7.156z"
        fill="#b2a99c"
      />
      <path
        d="M17.545 27.683c.715 1.05 1.877 2.404 2.756 3.171 1.318 1.153 3.038 2.353 5.071 3.858l.35-.58c-1.785-1.421-4.361-2.985-5.792-4.78-1.649-2.069-2.694-5.806-3.06-5.458-.49.466-.428 2.169.675 3.79z"
        fill="#3f3737"
      />
      <path
        d="M13.976 24.31c-1.236.483-.193 3.562.258 4.187 1.302 1.806 3.444 3.74 5.124 4.702 1.791 1.026 3.873 1.896 6.322 3.001l.087-1.37c-3.181-2.022-5.975-4.174-6.994-5.105-.923-.842-4.137-5.674-4.796-5.415z"
        fill="#b2a99c"
      />
      <path
        d="M14.19 25.444c-.479.543.278 2.417.727 2.98 1.467 1.84 2.79 3.291 4.46 4.27 1.845 1.082 3.792 2.055 6.24 3.16l-.013-.797c-2.591-1.498-6.499-4.344-7.252-5.215-1.274-1.471-3.805-4.802-4.161-4.398z"
        fill="#3f3737"
      />
      <path
        d="M11.43 29.035c.359 1.962 1.64 3.075 2.055 3.41 3.366 2.712 6.713 3.207 12.425 4.73l-.346-1.395c-2.21-.762-5.655-2.026-7.886-3.963-4.931-4.28-6.616-4.785-6.249-2.782z"
        fill="#b2a99c"
      />
      <path
        d="M12.418 29.705c.143.62.863 1.774 1.681 2.377 3.584 2.64 5.845 3.169 11.556 4.692L25.4 36c-2.21-.762-5.8-2.29-8.178-4.043-3.764-2.776-5.17-3.844-4.804-2.252z"
        fill="#3f3737"
      />
      <path
        d="M16.433 34.203c-5.244-2.935-6.362-1.92-5.088.448.35.65 1.232 1.654 2.4 1.989 2.647.759 7.442 1.098 12.913 1.769l-.754-1.4c-3.787-.812-7.901-1.927-9.47-2.806z"
        fill="#b2a99c"
      />
      <path
        d="M16.46 34.832c-3.487-1.505-5.422-2.238-4.381-.525.294.485 1.404 1.673 2.488 1.916 2.565.575 6.502 1.374 11.95 1.932l-.353-.966c-3.786-.812-7.893-1.576-9.703-2.357z"
        fill="#3f3737"
      />
      <path
        d="M14.831 40.756c1.692.115 8.525.158 13.497-.099l-.339-2.24c-3.54-.634-12.625-1.721-14.056-1.818-1.43-.097-3 .035-1.939 1.92.588 1.044 1.405 2.138 2.837 2.237z"
        fill="#b2a99c"
      />
      <path
        d="M27.958 38.729c-3.228-.456-12.03-1.335-14.324-1.403-.698-.021-1.114.404-.74 1.147.466.93 1.254 1.688 2.285 1.663 1.604-.038 11.35-.189 12.94-.271z"
        fill="#3f3737"
      />
      <path
        d="M16.438 44.032c1.687-.167 7.197-1.199 12.057-2.28l-.72-2.261c-3.596-.035-11.488.449-12.915.591-1.427.143-2.953.535-1.593 2.216.754.932 1.742 1.875 3.17 1.734z"
        fill="#b2a99c"
      />
      <path
        d="M28.26 41.367l-.354-1.47c-3.302.034-11.13.584-13.264.946-.689.117-1.161.568-.67 1.238.615.84 1.54 1.682 2.493 1.29 1.304-.538 10.242-1.658 11.795-2.004z"
        fill="#3f3737"
      />
      <path
        d="M17.912 46.493c1.657-.361 6.418-1.838 11.12-3.475l-1.012-2.311c-3.576.38-10.73 1.739-12.132 2.046-1.4.306-2.871.872-1.325 2.385.857.838 1.947 1.66 3.35 1.355z"
        fill="#b2a99c"
      />
      <path
        d="M28.754 42.664l-.4-1.576c-3.313.462-10.457 1.835-12.636 2.363-.679.165-1.032.771-.467 1.38.708.763 1.71 1.503 2.612 1.002 1.233-.684 9.389-2.647 10.891-3.17z"
        fill="#3f3737"
      />
      <path
        d="M20.438 48.806c1.59-.59 4.71-2.05 9.19-4.488l-1.163-2.156c-3.487.878-9.21 2.724-10.555 3.224-1.343.5-2.72 1.266-.978 2.548.966.71 2.16 1.371 3.506.872z"
        fill="#b2a99c"
      />
      <path
        d="M29.303 44.006l-.59-1.439c-3.255.904-8.904 2.677-10.876 3.568-.636.287-.976.799-.33 1.323.807.656 2.17 1.25 2.993.627 1.125-.85 7.389-3.351 8.803-4.08z"
        fill="#3f3737"
      />
      <path
        d="M23.696 50.843c1.46-.863 3.356-2.235 7.332-5.43l-1.644-1.802c-3.276 1.484-7.55 3.584-8.784 4.316-1.233.73-2.452 1.73-.51 2.68 1.077.528 2.37.966 3.606.236z"
        fill="#b2a99c"
      />
      <path
        d="M30.653 45.163l-.795-1.201c-3.076 1.275-7.418 3.454-9.2 4.682-.575.396-.691 1 .037 1.401.912.502 2.172.779 2.871.02.956-1.036 5.824-3.934 7.087-4.902z"
        fill="#3f3737"
      />
      <path
        d="M27.264 52.194c1.287-1.105 1.668-1.842 5.174-5.548l-1.873-1.561c-3.044 1.914-6.262 3.962-7.386 4.854-1.123.891-2.239 2.196-.141 2.725 1.478.373 2.903.666 4.226-.47z"
        fill="#b2a99c"
      />
      <path
        d="M32.033 46.45l-.95-1.082c-2.875 1.681-6.115 3.856-7.75 5.273-.415.36-.672 1.08.109 1.364.807.294 2.498.498 3.088-.348.807-1.157 4.383-4.077 5.503-5.207z"
        fill="#3f3737"
      />
      <path
        d="M31.603 52.995c2.07-2.477 2.96-4.05 3.97-6.152l-2.74-.925c-3.086 2.142-5.977 4.96-6.925 6.742-.556 1.047.215 1.718 2.04 1.774 1.61.05 2.789-.403 3.655-1.44z"
        fill="#b2a99c"
      />
      <path
        d="M35.106 47.986l-2.539-1.39c-2.51 2.104-5.235 4.64-5.763 6.151-.249.713.414.906 1.276.903 1.289-.004 3.143-.494 3.435-.897a201.152 201.152 0 013.591-4.767z"
        fill="#3f3737"
      />
      <path
        d="M53.03 33.803c-5.989 1.437-8.566 6.153-12.251 8.666-3.069 2.09-7.277 5.877-9.323 8.371-3.499 4.264-8.738 13.516-7.056 13.516.342 0 1.112-.794 1.592-1.561L58.973 36.87z"
        fill="#b2a99c"
      />
      <path
        d="M58.542 37.708l-2.183 1.682-1.27.029c-1.611 2.835-4.62 2.056-12.555 6.941-5.063 3.118-13.745 12.003-16.542 16.435-.273.883-1.527 5.305-.54 5.305 1.294 0 4.502-2.896 6.44-4.125.566.428 2.254.533 3.19-.319.477-.434-.014-1.483-.11-1.803 16.765-7.05 15.39-8.573 17.164-14.898.87-2.578 3.035-4.003 4.649-5.53z"
        fill="#f5f3f2"
      />
      <path d="M47.915 45.732l1.43.852c1.85-1.976 3.94-4.797 6.822-4.797.477 0-2.005.854-4.515 5.128l.509.04c1.703-3.418 4.453-5.067 5.029-5.638-2.658-.241-4.724.66-9.275 4.415zM56.584 39.466h-3.283l1.92-4.119 3.112 2.256z" />
      <path
        d="M56.514 39.466c-.113.559-.185 1.196-.04 1.815 2.47.336 2.9.125 4.083-.263l-.788-1.646z"
        fill="#dfcac1"
      />
      <path
        d="M49.663 41.788c-1.34-.557-2.29-1.68-2.724-2.866"
        fill="none"
        stroke="#3f3737"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.265}
      />
      <ellipse
        cx={65.876}
        cy={-3.101}
        fill="#f5f3f2"
        rx={1.266}
        ry={1.144}
        transform="rotate(37.36)"
      />
      <ellipse
        cx={65.902}
        cy={-3.092}
        fillOpacity={0.995}
        rx={0.83}
        ry={0.75}
        transform="rotate(37.36)"
      />
      <path d="M34.896 61.614c-.396.113-.803.361-1.217.698" fill="none" />
      <g stroke="#b2a99c" strokeWidth={0.265}>
        <path
          d="M31.783 64.048c-.261-.402-.61-1.517-.524-3.02 1.038-1.498 2.48-2.496 4.002-3.395.49 1.008-.739 2.72-.77 3.65.32.29.569.641.7.957"
          fill="#f5f3f2"
        />
        <path
          d="M31.26 61.028c-.293.389-.575.803-.787 1.121M35.26 57.633c.34-.204.614-.317.887-.43"
          fill="none"
        />
      </g>
      <circle cx={54.473} cy={37.322} fill="#fff" r={0.277} />
      <path
        d="M41.73 42.471c-4.319-.101-12.571-6.345-15.428-8.21-2.104-1.375-1.369 3.207 1.542 6.464.336 2.162 2.767 6.037 6.182 7.373 2.416-2.241 7.438-2.812 8.754-4.36.18-.21.141-.634-.12-.897-.222-.224-.646-.363-.93-.37z"
        fill="#524848"
      />
      <path
        d="M56.514 39.466l2.298-1.955 1.833 3.626c-.223 0-.626-1.095-2.149-1.043-.81.027-1.95-.194-1.982-.628z"
        fill="#c8592b"
      />
      <path
        d="M34.68 62.118c.479.796 1.785.843 2.618.504.883-.36-.112-2.001-.112-2.001zM18.963 79.908c1.044-.384 2.382-1.451 2.979-2.372.51.66.934.808 1.34.808.684 0 1.157-.476 1.584-1.603-.465.923-1.018 1.135-1.501 1.162-.475.027-.917-.237-1.413-.759-.726.847-2.25 2.093-3.163 2.206-.507.062-1.036-.349-.738-1.687-.38.913-.938 2.916.912 2.245z"
        fill="#b2a99c"
      />
      <path
        d="M58.474 36.614c-.132 1.214-1.102 1.178-1.471 1.178-1.694 0-1.808-2.733-3.949-3.989 2.775-1.018 7.222 1.84 6.194 3.683-.248-.363-.774-.872-.774-.872z"
        fill="#3f3737"
      />
      <path
        d="M53.309 33.722c-.086.024-.172.05-.255.081 2.112 1.28 2.005 3.596 3.654 3.596.323 0 1.272-.015 1.555-.744-.31.347-.745.367-1.275.367-1.456 0-1.67-2.27-3.68-3.3z"
        fill="#f5f3f2"
      />
      <path
        d="M56.514 39.466c-.03-1.528 1.78-1.587 1.96-2.852 1.517 1.346 2.667 4.523 2.17 4.523-.222 0-.627-1.199-2.15-1.147-.81.028-1.872-.21-1.98-.524z"
        fill="#ec8458"
      />
      <path
        d="M58.729 37.578l-.403.377a16.658 16.658 0 011.706 1.99c-.42-1.074-.826-1.734-1.303-2.367z"
        fill="#f9dbce"
      />
      <path
        d="M58.852 36.586c.155.26.343.403.537.478.01-.08.013-.16.007-.242-.153-.003-.303-.056-.544-.236z"
        fill="#f5f3f2"
      />
      <path
        d="M43.003 58.505c-.285-.002-.589.05-.904.17l-5.144 1.967c-.801.306-.173 1.916.41 1.624l3.952-1.983c-.385.614-.399 1.499.11 2.42-.082-1.16.378-2.23 1.3-2.86.809.197.746 1.69.287 2.575.524-.37.873-.668 1.043-1.154-.054.75-.534 1.86-1.014 2.418 1.069-.395 1.526-1.375 1.755-2.495.742-1.07-.255-2.67-1.795-2.682z"
        fill="#e0774a"
      />
      <path
        d="M40.69 59.16a2.515 2.515 0 00-1.1.244L34.4 61.801c-.809.374-.045 1.997.538 1.654l3.956-2.33c-.356.662-.309 1.58.282 2.498-.166-1.195.236-2.336 1.147-3.052.852.147.892 1.698.479 2.646.516-.42.857-.753 1-1.268-.004.78-.424 1.963-.883 2.575 1.08-.484 1.486-1.53 1.645-2.707.668-1.118-.37-2.632-1.873-2.657z"
        fill="#ec8458"
      />
    </g>
  </LogoRoot>
);

export default Logo;
